//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import store from '@/store'
import { sysOrgEdit, getOrgTree } from '@/api/modular/system/orgManage'
export default {
  data () {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      orgTree: [],
      visible: false,
      confirmLoading: false,
      formLoading: true,
      form: this.$form.createForm(this)
    }
  },
  methods: {
    // 初始化方法
    edit (record) {
      this.visible = true
      this.getOrgTree()
      setTimeout(() => {
        this.form.setFieldsValue(
          {
            id: record.id,
            name: record.name,
            code: record.code,
            sort: record.sort,
            pid: record.pid,
            remark: record.remark
          }
        )
      }, 100)
    },

    /**
     * 獲取機構樹，並加載於表單中
     */
    getOrgTree () {
      getOrgTree().then((res) => {
        this.formLoading = false
        if (!res.success) {
          this.orgTree = []
          return
        }
        const admintype = store.getters.admintype
        // eslint-disable-next-line eqeqeq
        if (admintype == '1') {
          this.orgTree = [{
            'id': '-1',
            'parentId': '0',
            'title': '頂級',
            'value': '0',
            'pid': '0',
            'children': res.data
          }]
        } else {
          this.orgTree = res.data
        }
      })
    },

    handleSubmit () {
      const { form: { validateFields } } = this
      this.confirmLoading = true
      validateFields((errors, values) => {
        if (!errors) {
          sysOrgEdit(values).then((res) => {
            if (res.success) {
              this.$message.success('編輯成功')
              this.visible = false
              this.confirmLoading = false
              this.$emit('ok', values)
              this.form.resetFields()
            } else {
              this.$message.error('編輯失敗：' + res.message)
            }
          }).finally((res) => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel () {
      this.form.resetFields()
      this.visible = false
    }
  }
}
